import request from '@/utils/request'

export function getTaskDetail(params) {
    return request({
        url: `/projectTask/getById`,
        method: 'GET',
        params,
    })
}

export function deleteMember(data) {
    return request({
        url: `/projectTask/deleteTaskMember`,
        method: 'POST',
        data,
    })
}

export function createMember (data) {
    return request({
        url: data.id ? `/projectTask/updateTaskMember/${data.id}` : `/projectTask/addTaskMember`,
        method: 'POST',
        data
    })
}

export function saveTaskExpenditure (data) {
    return request({
        url: '/projectTask/addExpenditures',
        method: 'POST',
        data
    })
}

export function getTaskFileList (params) {
    return request({
        url: '/projectTask/getTaskFiles',
        method: 'GET',
        params
    })
}

export function createDependency (data) {
    return request({
        url: '/projectTask/bindTaskRelation',
        method: 'POST',
        data
    })
}

export function deleteDependency (data) {
    return request({
        url: '/projectTask/unbindTaskRelation',
        method: 'POST',
        data
    })
}

export function getDependencyList (data) {
    return request({
        url: `/projectTask/listTaskByDependency?relationType=${data.relationType}&taskId=${data.taskId}`,
        method: 'POST',
        data
    })
}

export function addTaskApproval (data) {
    return request({
        url: '/projectTask/addTaskApproval',
        method: 'POST',
        data
    })
}

export function getTaskApproval (params) {
    return request({
        url: '/projectTask/getApprovalLogs',
        method: 'GET',
        params
    })
}

export function addTaskFlows (data) {
    return request({
        url: '/projectTask/addTaskApprovalFlow',
        method: 'POST',
        data
    })
}

export function delTaskFlows (data) {
    return request({
        url: '/projectTask/deleteTaskApprovalFlow',
        method: 'POST',
        data
    })
}

export function updateTaskFlows (data) {
    return request({
        url: '/projectTask/updateFlowsOrder',
        method: 'POST',
        data
    })
}

export function updateFlows (data) {
    return request({
        url: '/projectTask/updateFlows',
        method: 'POST',
        data
    })
}

export function getTaskFlowsList (params) {
    return request({
        url: '/projectTask/getTaskFlows',
        method: 'GET',
        params
    })
}








