import request from '@/utils/request'

export function getMemberList(params) {
    return request({
        url: `/projectInfo/getProjectMemeberInfos`,
        method: 'GET',
        params,
    })
}

export function createMember (data) {
    return request({
        url: data.id ? `/projectInfo/update/${data.id}` : `/projectInfo/addProjectMember`,
        method: 'POST',
        data
    })
}

export function deleteMember(data) {
    return request({
        url: '/projectInfo/removeProjectMember',
        method: 'POST',
        data,
    })
}

export function deleteStakeholder(data) {
    return request({
        url: '/projectInfo/removeProjectStakeholder',
        method: 'POST',
        data,
    })
}

export function getProjectPrincipal(params) {
    return request({
        url: '/projectInfo/getProjectLeaders',
        method: 'GET',
        params,
    })
}

export function getProjectDetail(params) {
    return request({
        url: '/projectInfo/getById',
        method: 'GET',
        params,
    })
}

export function getCustomerStakeholderList(params) {
    return request({
        url: '/projectInfo/getCustomerStakeholders',
        method: 'GET',
        params,
    })
}

export function getProjectStakeholderList(params) {
    return request({
        url: '/projectInfo/getProjectStakeholders',
        method: 'GET',
        params,
    })
}

export function createProjectStakeholder (data) {
    return request({
        url: '/projectInfo/addProjectStakeholder',
        method: 'POST',
        data
    })
}

export function getCategoryList(params) {
    return request({
        url: '/expenditureCategory/page',
        method: 'GET',
        params,
    })
}

export function saveProjectExpenditure (data) {
    return request({
        url: '/projectInfo/saveProjectExpenditure',
        method: 'POST',
        data
    })
}

export function saveProjectRemark (data) {
    return request({
        url: '/projectInfo/saveProjectRemark',
        method: 'POST',
        data
    })
}

export function getProjectFileList(params) {
    return request({
        url: '/fileInfo/page',
        method: 'GET',
        params,
    })
}

export function createProjectFile(data) {
    return request({
        url: data.id ? `/fileInfo/update/${data.id}` : `/fileInfo/create`,
        method: 'POST',
        data,
    })
}

export function deleteProjectFile(data) {
    return request({
        url: '/fileInfo/delete',
        method: 'POST',
        data,
    })
}

export function getAfterSaleList(params) {
    return request({
        url: '/afterSaleCare/page',
        method: 'GET',
        params,
    })
}

export function createAfterSale(data) {
    return request({
        url: data.id ? `/afterSaleCare/update/${data.id}` : `/afterSaleCare/create`,
        method: 'POST',
        data,
    })
}

export function getProductByProject(params) {
    return request({
        url: '/afterSaleCare/listProjectProducts',
        method: 'GET',
        params,
    })
}

export function getProductAndAccessoriesByProject(params) {
    return request({
        url: 'product/listProductsAndAccessories',
        method: 'GET',
        params,
    })
}

export function getLogList(params) {
    return request({
        url: '/afterSaleCare/listMaintenanceLog',
        method: 'GET',
        params,
    })
}

export function setMaintenanceDate(data) {
    return request({
        url: '/afterSaleCare/setMaintenanceDate',
        method: 'POST',
        data,
    })
}

export function setMaintenanceStatus(data) {
    return request({
        url: '/afterSaleCare/setMaintenanceStatus',
        method: 'POST',
        data,
    })
}

export function deleteAfterSale(data) {
    return request({
        url: '/afterSaleCare/delete',
        method: 'POST',
        data,
    })
}

export function getGanttData(params) {
    return request({
        url: '/projectTask/getGanttChart',
        method: 'GET',
        params,
    })
}

export function bindTaskMilestone(data) {
    return request({
        url: '/milestone/bindOneTask',
        method: 'POST',
        data,
    })
}

export function unbindTaskMilestone(data) {
    return request({
        url: '/milestone/unbindOneTask',
        method: 'POST',
        data,
    })
}

export function importTaskTemplate(data) {
    return request({
        url: '/taskTemplate/importTaskTemplate',
        method: 'POST',
        data,
    })
}

export function saveTaskTemplate(data) {
    return request({
        url: '/taskTemplate/saveTaskTemplate',
        method: 'POST',
        data,
    })
}

export function getProductsAndAccessories(params) {
    return request({
        url: '/product/listProductsAndAccessories',
        method: 'GET',
        params,
    })
}







