<template>
  <a-card
      :title="type === 1 ? '预算支出' : '实际支出'"
      :bodyStyle="{'height': '364px'}"
  >
    <div slot="extra">
      <span class="font-c9" v-if="type === 1">项目预算（元）：</span>
      <span class="font-c9" v-if="type === 2">当前实际支出（元）：</span>
      <span>{{ value.totalBalance }}</span>
      <a-button size="small" type="primary" style="margin-left: 20px" ghost @click="saveData" v-if="canModify">保存</a-button>
    </div>
    <a-table
        :columns="columns"
        :data-source="data"
        rowKey="id"
        bordered
        :pagination="false"
        :scroll="{ y: 200 }"
    >
      <template
          v-for="col in columns"
          :slot="col.dataIndex"
          slot-scope="text, record, index"
      >
        <div :key="col.dataIndex">
          <template v-if="record.editable">
            <template v-if="col.dataIndex === 'expenditureCategoryId'">
              <a-select
                  placeholder="请选择支出类目"
                  :value="text"
                  @change="e => itemChange(e, record.id, col.dataIndex)"
                  style="width: 180px"
              >
                <a-select-option :value="item.id" v-for="(item) in categoryList" :key="item.id">{{ item.categoryName }}</a-select-option>
              </a-select>
            </template>
            <template v-else-if=" (record.expenditureCategory === '设备采购费' && col.dataIndex === 'expenditureContent')">
              <a-select
                  show-search
                  :value="text"
                  placeholder="请选择关联产品"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="searchProduct"
                  @change="e => editProductChange(e, record.id, col.dataIndex)"
                  style="width: 180px">
                <a-spin v-if="searchLoading" slot="notFoundContent" size="small" />
                <a-select-option :value="item.id" v-for="(item) in productList" :key="item.id">{{ item.productName }}({{ item.productFlag === 1 ? '配件' : '产品' }})</a-select-option>
              </a-select>
            </template>
            <template v-else>
              <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :value="text"
                  @change="e => itemChange(e.target.value, record.id, col.dataIndex)"
              />
            </template>
          </template>
          <span v-else>
            <template v-if="col.dataIndex === 'expenditureCategoryId'">{{ record.expenditureCategory }}</template>
            <template v-else>{{ text }}</template>
          </span>
        </div>
      </template>
      <template slot="totalPrice" slot-scope="text, record">
        {{ record.quantity * record.unitPrice }}
      </template>
      <template slot="id" slot-scope="text, record, index">
        <template v-if="record.editable">
          <img src="@/assets/image/common/save.png" @click="() => save(record.id)" />
          <a-popconfirm title="确定取消?" @confirm="() => cancel(record.id)">
            <img src="@/assets/image/common/cancel.png" alt="" class="margin-l20">
          </a-popconfirm>
        </template>
        <img src="@/assets/image/common/edit.png" alt=""  :disabled="editingKey !== ''" @click="() => edit(record.id)" v-else>
        <a-popconfirm title="确定删除?" @confirm="() => delItem(record, index)">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20">
        </a-popconfirm>
      </template>
    </a-table>
    <a-row class="margin-t20" v-if="canModify">
      <a-form-model
          ref="form"
          layout="inline"
          :model="form"
          @submit="submit"
          @submit.native.prevent
      >
        <a-form-model-item prop="expenditureCategoryId">
          <a-select v-model="form.expenditureCategoryId" placeholder="请选择支出类目" style="width: 205px" @change="categoryChange">
            <a-select-option :value="item.id" v-for="(item) in categoryList" :key="item.id">{{ item.categoryName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="expenditureContent">
          <template v-if="form.expenditureCategory === '设备采购费'">
            <a-select
                show-search
                v-model="form.productId"
                placeholder="请选择关联商品"
                style="width: 205px"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="searchProduct"
                @change="productChange">
              <a-spin v-if="searchLoading" slot="notFoundContent" size="small" />
              <a-select-option :value="item.id" v-for="(item) in productList" :key="item.id">{{ item.productName }}({{ item.productFlag === 1 ? '配件' : '产品' }})</a-select-option>
            </a-select>
          </template>
          <template v-else>
            <a-input v-model="form.expenditureContent" type="text" placeholder="请输入具体内容" style="width: 205px"></a-input>
          </template>
        </a-form-model-item>
        <a-form-model-item prop="quantity">
          <a-input v-model="form.quantity" type="number" placeholder="请输入件数" style="width: 205px"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="unitPrice">
          <a-input v-model="form.unitPrice" type="number" placeholder="请输入单价"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
              type="success"
              html-type="submit"
              :disabled="
                    !form.expenditureCategoryId
                    || !(form.expenditureContent || form.productId)
                    || !form.quantity
                    || !form.unitPrice"
              class="bg-green width-150"
          >
            确认添加一行
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-row>
  </a-card>
</template>

<script>
import { getCategoryList, getProductsAndAccessories } from '../../../api/project/projectDetail'
import { saveTaskExpenditure } from '../../../api/task/taskDetail'
import { getProductList } from '@/api/product/product'

export default {
  name: 'editTable',
  props: {
    value: {
      type: [Array, Object],
      default: null
    },
    type: {
      type: Number,
      default: 1
    },
    taskId: {
      type: String,
      default: ''
    },
    canModify: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: [],
      cacheData: {},
      editingKey: '',
      form: {
        productId: null,
        quantity: null,
        unitPrice: null
      },
      columns: [
        {
          title: '类目名称',
          dataIndex: 'expenditureCategoryId',
          scopedSlots: { customRender: 'expenditureCategoryId' }
        },
        {
          title: '具体内容',
          dataIndex: 'expenditureContent',
          scopedSlots: { customRender: 'expenditureContent' }
        },
        {
          title: '件数',
          dataIndex: 'quantity',
          scopedSlots: { customRender: 'quantity' }
        },
        {
          title: '单价（元）',
          dataIndex: 'unitPrice',
          scopedSlots: { customRender: 'unitPrice' }
        },
        {
          title: '合计（元）',
          dataIndex: 'totalPrice',
          scopedSlots: { customRender: 'totalPrice' }
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      categoryList: [],
      productList: [],
      searchLoading: false,
    }
  },
  mounted () {
    if (this.value.expenditureVOList) this.data = this.value.expenditureVOList
    this.cacheData = this.data.map(item => ({ ...item }))
    this.getCategory()
    this.getProduct()
  },
  methods: {
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(id) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => id === item.id);
      const targetCache = newCacheData.find(item => id === item.id);
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        console.log(this.data)
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
    },
    cancel(id) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      this.editingKey = '';
      if (target) {
        Object.assign(target, this.cacheData.find(item => id === item.id));
        delete target.editable;
        this.data = newData;
      }
    },
    getCategory() {
      getCategoryList({
        pageNum: 1,
        pageSize: 10000,
      }).then(res => {
        this.categoryList = res.data.list
      })
    },
    submit () {
      this.form.id = Date.now()
      this.form.expenditureType = this.type
      this.form.taskId = this.taskId
      this.form.totalPrice = this.form.quantity * this.form.unitPrice
      this.data.push(this.form)
      this.form = {}
    },
    itemChange (value, id, column) {
      if(column === 'column' || column === 'unitPrice') value = parseFloat(value)
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.data = newData;
        if (column === 'expenditureCategoryId') {
          let data = this.categoryList.find(item => item.id === value)
          target['expenditureCategory'] = data.categoryName
        }
      }
    },
    editProductChange (value, id, column) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      if (target) {
        target['productId'] = value;
        target['expenditureContent'] = this.productList.find(item => item.id === value).productName;
        this.data = newData;
      }
    },
    delItem (item, index) {
      this.data.splice(index, 1)
    },
    saveData () {
      saveTaskExpenditure({
        expenditureType: this.type,
        expenditures: this.data,
        projectTaskId: this.taskId
      }).then(() => {
        this.$message.success('保存成功！')
        this.$emit('change', this.data)
      })
    },
    categoryChange (value) {
      this.form.expenditureCategory = this.categoryList.find(item => item.id === value).categoryName
    },
    getProduct (name) {
      this.searchLoading = true
      getProductsAndAccessories({
        productName: name
      }).then(res => {
        this.productList = res.data
        this.$forceUpdate()
        this.searchLoading = false
      })
    },
    searchProduct (value) {
      this.getProduct(value)
    },
    productChange () {
      this.form.expenditureContent = this.productList.find(item => item.id === this.form.productId).productName
    }
  }
}
</script>

<style scoped>

</style>
